/*.ui.toggle.checkbox input:checked ~ .box::before, .ui.toggle.checkbox input:checked ~ label::before {
    background-color: #db2828 !important;
}
.ui.toggle.checkbox input:focus:checked~.box:before,
.ui.toggle.checkbox input:focus:checked~label:before {
    background-color: #db2828 !important
}
*/
.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
    background-color: grey;
}
.red{
    color: red;
}
.green{
    color: green;
}